import { Fragment } from "react";

const NavigationCrumbs = ({ getOffsetPageCount, offset, perPage, count, pages, setOffset }) => {
  return (
    <div className="flex items-center flex-wrap py-3">
      <div className="text-sm text-gray-400 mx-auto my-2 sm:ml-0">
        Showing {getOffsetPageCount(offset, perPage)} to {count >= (offset + 1) * perPage ? (offset + 1) * perPage : count} <span>of {count}</span>
      </div>
      <div className="flex items-center flex-wrap justify-center w-full sm:w-auto sm:justify-end space-x-1">
        {Array(pages)
          .fill()
          .map((_, i, arr) => {
            return (
              <Fragment key={i}>
                {offset > 0 && i === 0 && (
                  <div className="w-8 h-8 cursor-pointer text-sm flex justify-center items-center bg-white rounded shadow hover:bg-gray-50" onClick={() => setOffset(offset - 1)}>
                    <i className="far fa-fw fa-chevron-double-left"></i>
                  </div>
                )}
                {[3].includes(i) && [0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(offset) && (
                  <div className="h-8 w-auto flex gap-x-1 px-2 items-end">
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                  </div>
                )}
                {arr.length > 9 && ([0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(i) || ([2, arr.length - 3].includes(offset) && offset === i)) ? (
                  <>
                    <div
                      className={`w-8 h-8 cursor-pointer text-sm flex justify-center items-center bg-white rounded shadow hover:bg-gray-50 ${
                        offset === i ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""
                      }`}
                      onClick={() => setOffset(i)}
                    >
                      {i + 1}
                    </div>
                  </>
                ) : offset === i && offset > 2 && offset <= arr.length - 3 && ![0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(i) ? (
                  <>
                    <div className="h-8 w-auto flex gap-x-1 px-2 items-end">
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    </div>
                    <div
                      className={`w-8 h-8 cursor-pointer text-sm flex justify-center items-center bg-white rounded shadow hover:bg-gray-50 ${
                        offset === i ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""
                      }`}
                      onClick={() => setOffset(i)}
                    >
                      {i + 1}
                    </div>
                    <div className="h-8 w-auto flex gap-x-1 px-2 items-end">
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    </div>
                  </>
                ) : null}
                {offset + 1 !== pages && i + 1 === pages && (
                  <div className="w-8 h-8 cursor-pointer text-sm flex justify-center items-center bg-white rounded shadow hover:bg-gray-50" onClick={() => setOffset(offset + 1)}>
                    <i className="far fa-fw fa-chevron-double-right"></i>
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default NavigationCrumbs;
