import { CubeTransparentIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDashboardData, manageDashboardData } from "src/actions/dashboard";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Button";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import QuickMenu from "src/components/Shared/QuickMenu";
import Toggle from "src/components/Shared/Toggle";
import { hostUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { validate as uuidValidate } from "uuid";
import Input from "../Shared/Forms/Input";
import SelectMenu from "../Shared/SelectMenu";
import TextArea from "../Shared/TextArea";

const DashboardEntry = ({ placeholder, innerRef, draggableProps, dragHandleProps, dashboard, isDefault, updateDefaultKey, deleteDashboard, getDashboardData, manageDashboardData, ...props }) => {
  const [changedState, setChangedState] = useState(false);
  const [tempDashboard, setTempDashboard] = useState(dashboard);

  const [dashboardCredentials, setDashboardCredentials] = useState([]);

  useEffect(() => {
    const getDashboardCredentials = async () => {
      const ac = new AbortController();

      try {
        const res = await fetchData("POST", `${hostUrl}/dashboard/credential/get`, {}, ac.signal);
        const data = await res.json();

        if (data.status === 200) {
          let creds = data.data.map((cred) => {
            return { value: cred._id, key: cred.name };
          });
          setDashboardCredentials(creds);
        } else {
          throw new Error(data.message);
        }
      } catch (err) {
        throw new Error(err.message);
      }
      return () => ac.abort();
    };

    getDashboardCredentials();
  }, []);

  const updateDashboard = (payload, key) => {
    setTempDashboard({ ...tempDashboard, ...payload });
    setChangedState(true);
  };

  const undoStateChanges = () => {
    setTempDashboard(dashboard);
    setChangedState(false);
  };

  const onSubmit = async () => {
    try {
      let item = tempDashboard;
      if (uuidValidate(item._id)) {
        item._id = null;
      }

      const { message } = await manageDashboardData({
        dashboards: [item],
      });

      toast.success(message);
      setChangedState(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <tr ref={innerRef} {...draggableProps} className=" py-4 justify-between w-full group relative flex flex-col items-start sm:table-row bg-white rounded-md">
      {placeholder}
      <td className="w-full pt-5 sm:w-[50%] bg-white md:min-w-[200px] lg:min-w-[320px] xl:min-w-[400px] align-top sm:py-4 lg:w-[100%] relative whitespace-nowrap flex xl:items-center lg:align-top px-3 lg:py-4 text-sm text-gray-500 lg:block sm:table-cell">
        <div className="w-full flex items-center sm:block">
          <p {...dragHandleProps} className="hidden sm:group-hover:flex absolute left-3 bottom-3 p-1.5 rounded-md bg-gray-50">
            <CubeTransparentIcon className="text-gray-300 mr-2 h-5 w-5" />
            <span className="text-gray-400">Drag to reorder</span>
          </p>
          <div className="w-full mb-3">
            <Input type="text" name="dashboard-name" label="Name" error={false === "Name field is required"} value={tempDashboard.name} onChange={(e) => updateDashboard({ name: e.target.value })} />
          </div>
          <div className="lg:w-full flex justify-end items-center">
            <div className="md:max-h-[38px] ">
              <div className="items-center py-1.5 px-2.5 rounded-full bg-gray-50 flex space-x-2">
                <Toggle value={isDefault} setValue={() => updateDefaultKey(dashboard._id)} disabled={props.site.api_state === "domo"} />
                <span className={classNames("text-sm font-medium", isDefault && props.site.api_state !== "domo" ? "text-highlightColor" : "text-gray-400")}>
                  {isDefault ? "Default" : "Set default"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="bg-white w-full h-full sm:py-4 lg:align-top px-3 pr-5 lg:py-4 text-sm text-gray-500 block sm:table-cell">
        <div className="">
          <div className="h-full flex items-top gap-y-3 lg:mt-0 flex-col justify-start xl:max-w-5xl">
            {tempDashboard.dashboard_type === "custom" ? (
              <>
                <div className="w-full xl:w-4/5 h-full">
                  <TextArea label="Custom HTML" textType="code" value={tempDashboard.custom_html} onChange={(e) => updateDashboard({ custom_html: e.target.value })} />
                </div>
              </>
            ) : (
              <>
                <div className="w-full xl:w-4/5 h-full">
                  <SelectMenu
                    label="Domo Credential"
                    disabled={dashboardCredentials.length === 0}
                    options={dashboardCredentials || [{ key: "Select credentials", value: -1 }]}
                    startIndex={dashboardCredentials.length ? dashboardCredentials.map((object) => object.value).indexOf(dashboard.credential_id) : -1}
                    setOption={(option) => {
                      updateDashboard({ credential_id: option.value });
                    }}
                    emptyListText="No credentials have been created."
                  />
                </div>
                <div className="w-full xl:w-4/5 xl:mr-3">
                  <FrontLabeledInput label="Embed ID" type="text" value={tempDashboard.embed_id} onChange={(e) => updateDashboard({ embed_id: e.target.value })} />
                </div>
              </>
            )}
          </div>
          {props.site.api_state !== "domo" && (
            <div className="bg-white lg:hidden mt-3 h-full max-h-[40px] w-full flex items-center justify-end gap-x-2">
              <Button styleType="gray" disabled={!changedState} onClick={undoStateChanges}>
                Undo
              </Button>
              <Button styleType="secondary" disabled={!changedState} onClick={onSubmit}>
                Save changes
              </Button>
            </div>
          )}
        </div>
      </td>
      <td className="bg-transparent sm:bg-white absolute h-8 top-0 right-0 sm:h-8 sm:m-0 sm:relative whitespace-nowrap sm:px-3 sm:py-4 text-sm text-gray-500 align-bottom block w-full sm:table-cell sm:w-auto">
        {props.site.api_state !== "domo" && (
          <>
            <div className="absolute top-2 right-2">
              <QuickMenu
                disabled={!(!tempDashboard.is_default && !tempDashboard?.is_default)}
                items={[{ name: "Delete dashboard", onClick: () => deleteDashboard(dashboard._id), color: "text-red-500" }]}
              />
            </div>
            <div className="lg:flex hidden w-full items-center justify-end gap-x-2">
              <Button styleType="gray" disabled={!changedState} onClick={undoStateChanges}>
                Undo
              </Button>
              <Button styleType="secondary" disabled={!changedState} onClick={onSubmit}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getDashboardData, manageDashboardData })(DashboardEntry);
