let conversionNamesLg = {
  EQUALS: "=",
  GREATER_THAN: ">",
  LESS_THAN: "<",
};

let conversionNamesSm = {
  NOT_EQUALS: "≠",
  GREATER_THAN_EQUALS_TO: "≥",
  LESS_THAN_EQUALS_TO: "≤",
};

export const operatorNameConverter = (name) => {
  if (conversionNamesLg[name]) {
    return <div className="text-[24px] font-medium h-[17.5px] overflow-y-hidden">{conversionNamesLg[name]}</div>;
  }
  if (conversionNamesSm[name]) {
    return <div className="text-[21px] font-medium h-[17.5px] overflow-y-hidden">{conversionNamesSm[name]}</div>;
  }
  return <div className="font-semibold h-[17.5px] overflow-y-hidden">{name.split("_").join(" ")}</div>;
};
