import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PageHeader from "src/components/Shared/PageHeader";
import CardUserForm from "./CardUserForm";
import UserEntry from "./List/UserEntry";
import { useNavigate } from "react-router-dom";
import { hostUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import UserList from "./List/UserList";
import { getDashboardData, getGroupPermissionData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { utils, writeFile } from 'xlsx';
import { getUsers, getUsersExport, deleteUser } from "src/actions/user";
import { toast } from "react-toastify";
import DefaultModal from "src/components/Shared/DefaultModal";
import NavigationCrumbs from "src/components/Shared/NavigationCrumbs";
import ExportContainer from "../Shared/ExportContainer";

const UsersPageContent = ({ users, getUsers, getUsersExport, dashboards, operators, getOperatorData, getDashboardData, deleteUser, type }) => {
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [perPage, setPerpage] = useState("10");
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const [selected, setSelected] = useState({ user: {}, site: {} });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedGroupPermissions, setSelectedGroupPermissions] = useState([]);
  const [exportType, setExportType] = useState('xls');
  const [status, setStatus] = useState("");
  const [group, setGroup] = useState("");
  const [keyword, setKeyword] = useState("");

  const navigate = useNavigate();

  const loadDashboards = async (id) => {
    const ac = new AbortController();

    try {
      let res = await fetchData("POST", `${hostUrl}/user/details`, { id }, ac.signal);
      res = await res.json();

      const finalPermissions = {};
      const updatedStateCount = [];
      res.data.permissions.forEach((permission, i) => {
        finalPermissions[i] = permission;
        updatedStateCount.push(i);
      });

      setSelectedPermissions(finalPermissions);
      await getDashboardData({}, ac.signal);
      await getOperatorData({}, ac.signal);
    } catch (err) {
      console.dir(err.message);
    }

    return () => ac.abort();
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadUsers = async () => {
      setLoaded(false);
      const params = { limit: +perPage, offset: offset * +perPage };
      if (type) params.type = type;

      try {
        const userCount = await getUsers(params, ac.signal);
        setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        setPages(availablePages);
        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * +perPage + 1;
  };

  const removeUser = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteUser(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getAllGroupPermissionData = async (userId) => {
    const user = users.find((user) => user._id === userId);

    if (!user) {
      return;
    }

    const groupIds = user.groups.map((group) => group._id);

    if (!groupIds || !groupIds.length) {
      return;
    }

    try {
      const permissionData = await getGroupPermissionData(groupIds);
      if (permissionData.data && permissionData.data.length) {
        setSelectedGroupPermissions(permissionData.data);
      }
    } catch (err) {
      console.dir(err.message);
    }
  };

  const exportData = async () => {
    const params = { status: status, group: group, keyword: keyword };
    if (type) params.type = type;
    try {
      const res = await getUsersExport(params);
      let data = [];
      if(res.length > 0){
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          let groupName = [];
          if(element.groups.length > 0){
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name)
            }
          }
          data.push({
            'Name': element.name,
            'Email': element.email,
            'Username': element.username,
            'Type': element.type,
            'Receive notifications': (element.notifications)?'Yes':'No',
            'Groups': groupName.toString()
          })
        }
      }
      const headings = [[
        'Name',
        'Email',
        'Username',
        'Type',
        'Receive notifications',
        'Groups'
    ]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, ((type === 'user')?'User Report':'Admin Report')+((exportType === 'xls')?'.xls':'.csv'));
    } catch (err) {
      console.dir(err.message);
    }
  }

  return (
    <>
      <PageHeader>{type === "admin" ? "Admins" : "Users"}</PageHeader>
      <CardUserForm users={users} type={type} setLoaded={setLoaded} perPage={perPage} setPerpage={setPerpage} status={status} setStatus={setStatus} group={group} setGroup={setGroup} keyword={keyword} setKeyword={setKeyword} offset={offset} setCount={setCount} setPages={setPages} setOffset={setOffset} />
      <DefaultModal isOpen={!!deleteId} closeModal={() => setDeleteId(null)} onSubmit={removeUser} submitText="Delete" cancelText="Cancel" title="Delete user">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">Are you sure you want to delete {users.find((user) => user._id === deleteId)?.name}?</div>
      </DefaultModal>
      <DefaultModal
        isOpen={!!selected.user?._id}
        closeModal={() => {
          setSelected({ user: {}, site: {} });
          setSelectedGroupPermissions([]);
        }}
        onSubmit={() => {
          navigate(`/update-user/${selected?.user._id}?tab=dashboards`);
          setSelected({ user: {}, site: {} });
        }}
        removeSubmit={selected?.site.api_state === "domo" && !selected?.user.default_user}
        cancelText="Close"
        submitText="Edit permissions"
        title="View permissions"
      >
        <div className="w-full grid gap-y-3 mt-4 mb-8">
          <div className="text-gray-400 font-medium text-lg w-full flex justify-between">
            <p className="w-1/5">Dashboard</p>
            <p className="w-1/5">Column</p>
            <p className="w-1/5">Operator</p>
            <p className="w-1/5">Value</p>
          </div>
          <h3 className="text-lg text-left leading-6 font-medium text-gray-900">User Permissions</h3>
          {selectedPermissions[0]?._id &&
            Object.values(selectedPermissions).map((permission) => {
              return (
                <div key={permission._id} className="w-full flex justify-between text-gray-600">
                  <p className="w-1/5">{permission.dashboard_id ? dashboards.filter((dashboard) => dashboard._id === permission.dashboard_id)[0]?.name : "n/a"}</p>
                  <p className="w-1/5">{permission.column_name ? permission.column_name : "n/a"}</p>
                  <p className="w-1/5">{permission.operator_id ? operators.filter((operator) => operator._id === permission.operator_id)[0]?.name : "n/a"}</p>
                  <p className="w-1/5">{permission.column_value ? permission.column_value : "n/a"}</p>
                </div>
              );
            })}
          {selectedGroupPermissions.length > 0 && <h3 className="text-lg text-left leading-6 font-medium text-gray-900">Group Permissions</h3>}
          {selectedGroupPermissions.length > 0 &&
            selectedGroupPermissions.map((permission) => (
              <div key={permission._id} className="w-full flex justify-between text-gray-600">
                <p className="w-1/5">{permission.dashboard_name || "n/a"}</p>
                <p className="w-1/5">{permission.column_name ? permission.column_name : "n/a"}</p>
                <p className="w-1/5">{permission.operator_name || "n/a"}</p>
                <p className="w-1/5">{permission.column_value ? permission.column_value : "n/a"}</p>
              </div>
            ))}
        </div>
      </DefaultModal>
      <UserList setDeleteId={setDeleteId} deleteId={deleteId} loaded={loaded} dataExists={users.length > 0}>
        {users.length > 0 &&
          users.map((user) => (
            <UserEntry
              key={user._id}
              type={type}
              user={user}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              setSelected={(payload) => {
                setSelected(payload);
                loadDashboards(payload.user._id);
                getAllGroupPermissionData(user._id);
              }}
            />
          ))}
      </UserList>
      {count > perPage && users.length > 0 && loaded && (
        <NavigationCrumbs getOffsetPageCount={getOffsetPageCount} offset={offset} perPage={perPage} count={count} pages={pages} setOffset={setOffset} />
      )}
      
      <ExportContainer exportType={exportType} setExportType={setExportType} onSubmit={exportData}></ExportContainer>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
  };
};

export default connect(mapStateToProps, {
  getUsers,
  getUsersExport,
  getDashboardData,
  getOperatorData,
  deleteUser,
})(UsersPageContent);
