import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";
import { setSearchText } from "src/actions/search";
import { FeatureFlag } from "src/components/FeatureFlags/FeatureFlag";
import NavItem from "src/components/Shared/NavItem";
import { baseUrl, noProfile } from "src/config/host";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import useOnClickOutside from "src/hooks/useClickOutside";
import Logo from "../components/Admin/Logo";
import { classNames } from "../helpers/classNames";

const DefaultLayout = ({ user, menus, site, getMenuData, logout, children }) => {
  const userNavigation = [
    { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
    { name: "Change Password", path: "/change-password", navName: "Settings" },
    { name: "Sign out", path: "/" },
  ];
  const [currentNavigation, setCurrentNavigation] = useState("");

  const ref = useRef();
  // // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);

  const updateNavigation = (name) => {
    setCurrentNavigation(name);
  };

  useEffect(() => {
    const convertNavigation = [
      { name: "Dashboards", href: "/dashboards" },
      { name: "Users", href: "/users" },
      { name: "Users", href: "/manage-user" },
      { name: "Users", href: "/update-user" },
      { name: "Admins", href: "/admin-users" },
      { name: "Admins", href: "/update-admin" },
      { name: "Admins", href: "/admins" },
      { name: "Admins", href: "?isAdmin=true" },
      { name: "Groups", href: "/groups" },
      { name: "SSO", href: "/sso" },
      { name: "Activity", href: "/activity" },
      { name: "Settings", href: "/site-settings" },
    ];

    let path = window.location.href;
    let navigationTab = convertNavigation.find((tab) => path.includes(tab.href));
    if (navigationTab) updateNavigation(navigationTab.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        await getMenuData({}, ac.signal);
        setLoadedMenu(true);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadedMenu && !user) {
      window.location.href = "/login";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <FeatureFlagsProvider>
        <div className="flex flex-col h-full">
          <Transition.Root show={sidebarOpenMobile} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpenMobile}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-leftNavColor">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:ring-0 focus:border-highlightColor"
                          onClick={() => setSidebarOpenMobile(false)}
                        >
                          <span className="sr-only">Close Side Bar</span>
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="bg-headerColor pl-4">
                      <Logo />
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 space-y-1">
                        {menus.map((item) => (
                          <FeatureFlag key={item.name} feature={item.name}>
                            <NavItem
                              key={item.name}
                              activeItem={currentNavigation}
                              item={item}
                              onClick={(e) => {
                                setSidebarOpenMobile(false);
                                updateNavigation(e);
                              }}
                            />
                          </FeatureFlag>
                        ))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force leftNavColor to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <div className={classNames("flex w-full transition-all duration-150", sidebarOpen ? "lg:pl-0" : "")}>
            <div className="fixed top-0 z-30 flex-shrink-0 flex h-16 bg-headerColor shadow-md w-full">
              <Logo>
                <button type="button" className="px-4 text-headerTextColor focus:ring-0 focus:border-highlightColor lg:hidden" onClick={() => setSidebarOpenMobile(true)}>
                  <span className="sr-only">Open left navigation</span>
                  <Bars3BottomLeftIcon className="h-6 w-6 text-headerTextColor" aria-hidden="true" />
                </button>
                <button type="button" className="px-4 border-0 text-gray-500 focus:ring-0 focus:border-headerTextColor hidden lg:flex" onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <span className="sr-only">Open leftNavColor</span>
                  <Bars3BottomLeftIcon className="text-headerTextColor h-6 w-6" aria-hidden="true" />
                </button>
              </Logo>
              <div className="pr-6 flex w-full items-center justify-end">
                <div className="ml-4 flex items-center lg:ml-6">
                  <div className="text-headerTextColor text-sm font-medium h-6 w-22 flex items-center m-0">{user?.name ? user.name : null}</div>

                  {/* Profile dropdown */}
                  <Menu as="div" className="flex items-center">
                    <Menu.Button className="w-8 h-8 overflow-hidden rounded-full ml-3">
                      <span className="sr-only">Open user menu</span>
                      {user?.image ? (
                        <img className="w-full h-full object-cover" src={baseUrl + user?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
                      ) : (
                        <img className="w-full h-full object-cover" src={noProfile} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />
                      )}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute top-12 right-7 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <>
                                {item.name !== "Sign out" ? (
                                  <div key={item.name} onClick={() => updateNavigation(item.navName)}>
                                    <Link to={item.path} className={classNames(active ? "bg-gray-100 bg-color-06" : "", "block px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                                      {item.name}
                                    </Link>
                                  </div>
                                ) : (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      logout(site);
                                    }}
                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-highlightColor"
                                  >
                                    {item.name}
                                  </button>
                                )}
                              </>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex relative">
            {/* Static leftNavColor for desktop */}
            <div className={classNames("hidden z-20 fixed left-0 top-16 lg:flex lg:w-64 lg:flex-col h-full transition-all duration-150", sidebarOpen ? "left-0" : "-left-64")}>
              <nav className="flex flex-col px-2 py-4 space-y-1 h-full bg-leftNavColor shadow-md">
                {menus.map((item) => (
                  <FeatureFlag key={item.name} feature={item.name}>
                    <NavItem item={item} activeItem={currentNavigation} onClick={updateNavigation} />
                  </FeatureFlag>
                ))}
              </nav>
            </div>
            <div className={classNames("relative z-10 w-full pt-16 transition-all duration-100", sidebarOpen ? "lg:pl-64" : "lg:pl-0")}>
              <div className="w-full lg:max-w-[1400px] md:px-6 px-3 py-4 md:py-8">{children}</div>
            </div>
          </div>
        </div>
      </FeatureFlagsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
    user: state.auth.user,
    searchText: state.search.text,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, setSearchText })(DefaultLayout);
