import React from "react";
import { classNames } from "src/helpers/classNames";

const TextArea = ({
  autoComplete = "on",
  label,
  name,
  disabled = false,
  value = "",
  error = false,
  type = "text",
  labelClassNames = "",
  onChange = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  placeholder = "",
  textType = ""
}) => {
  return (
    <div className="relative">
      <label htmlFor={name} className={classNames(labelClassNames, "flex justify-between text-sm font-medium text-gray-700 mb-1", error ? "text-red-600" : "")}>
        {label} <div className="pl-2">{error ? "*Required" : ""}</div>
      </label>
      <div className="">
        <textarea
          className={classNames("min-h-[80px] block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor", textType === "code" ? "font-mono" : "")}
          placeholder={placeholder}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
