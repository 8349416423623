import { LinkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { hostUrl } from "src/config/host";
import { toast } from "react-toastify";
import PortalUserEntry from "src/components/PortalUsers/PortalUserEntry";
import PortalUserList from "src/components/PortalUsers/PortalUserList";
import Button from "src/components/Shared/Button";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import { getDatasetAccess, getDatasetQuery, manageDatasetPortalUsers, manageDatasetUsers, getClientData } from "src/actions/site";
import { domoUsersToPortalUsers, getDatasetUsers } from "src/actions/user";
import PageHeader from "../components/Shared/PageHeader";

const DatasetQuery = ({ creds, site, datasetUsers, datasetPortalUsers, manageDatasetUsers, domoUsersToPortalUsers, portal, manageDatasetPortalUsers }) => {
  const [count] = useState(0);
  const [perPage] = useState("10");
  const [pages] = useState(1);
  const [clientData, setClientData] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [isSync, setSync] = useState(false);

  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");

  const datasetId = portal ? site?.portal_dataset_id : site?.dataset_id;

  //const [dataUrl] = useState(`https://api.domo.com/v1/datasets/query/execute/${datasetId}`);

  const [checkedState, setCheckedState] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    const loadClientData = async () => {
      try {
        const getClient = await getClientData({ token: localStorage.getItem("access_token") }, ac.signal);
        setClientData(getClient);
      } catch (error) {
        console.dir(error);
      }
    };
    loadClientData();
    return () => ac.abort();
  }, []);

  useEffect(() => {
    const loadDatasetUsers = async () => {
      try {
        const data = await reloadUserPermissions();
        const checkedList = [];

        data.map((item, i) => (checkedList[i] = true));
        setCheckedState(checkedList);
        setSelectedUsers(data);

        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err);
      }
    };

    if ((datasetId !== undefined, clientData)) {
      loadDatasetUsers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, clientData]);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * +perPage + 1;
  };

  const reloadUserPermissions = async () => {
    setLoaded(false);

    try {
      const accessToken = await loadAccessToken();
      const dataRows = await loadDatasetQuery(accessToken);
      const res = await manageData(dataRows);

      setLoaded(true);

      return res.data;
    } catch (err) {
      setLoaded(true);
      console.dir(err.message);
    }
  };

  const loadAccessToken = async () => {
    const base64Cred = btoa(`${clientData?.dataset_client_id}:${clientData?.dataset_client_secret}`);
    const data = await getDatasetAccess(
      null,
      null,
      {
        Authorization: `Basic ${base64Cred}`,
      },
      authUrl
    );

    return data.access_token;
  };

  const loadDatasetQuery = async (accessToken) => {
    if (datasetId !== undefined) {
      const data = await getDatasetQuery(
        { sql: "SELECT * FROM table" },
        null,
        {
          Authorization: `bearer ${accessToken}`,
        },
        `https://api.domo.com/v1/datasets/query/execute/${datasetId}`
      );

      return data.rows;
    } else {
      return [];
    }
  };

  const manageData = async (data) => {
    if (portal) {
      const res = await manageDatasetPortalUsers({ rows: data }, null);
      return res;
    } else {
      const res = await manageDatasetUsers({ rows: data }, null);
      return res;
    }
  };

  const convertDomoUsersToPortalUsers = async (e) => {
    e.preventDefault();

    if (portal) {
      return;
    }

    try {
      const modifiedSelectedUsers = [];

      selectedUsers.map((user) => user.permissions.map((permission) => modifiedSelectedUsers.push(permission)));

      if (selectedUsers.length) {
        const message = await domoUsersToPortalUsers({ rows: modifiedSelectedUsers });
        toast.success(message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        await domoUsersToPortalUsers({ rows: [] });
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const manageUserSelect = (e, value, i) => {
    if (value) {
      setSelectedUsers([...selectedUsers, datasetUsers[i]]);

      const checkedList = checkedState;
      checkedList[i] = true;

      setCheckedState(checkedList);
    } else {
      const filteredUsers = selectedUsers.filter((user) => user._id !== datasetUsers[i]?._id);
      setSelectedUsers(filteredUsers);

      const checkedList = checkedState;
      checkedList[i] = false;

      setCheckedState(checkedList);
    }
  };

  const updateDomo = () => {
    setSync(true);
    fetch(`${hostUrl}/api/dataset-domo-cron/manage/ac807a50-a522-4dd4-8cac-2ff0a3544e2d`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Successfully updated data", {
              position: "top-right",
              autoClose: 1700,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setSync(false);
          }
        },
        (error) => {
          console.dir(error);
        }
      );
  };

  return (
    <>
      <PageHeader>Dataset Users</PageHeader>
      {!portal && (
        <div className="w-full flex justify-end">
          <Button styleType="secondary" onClick={convertDomoUsersToPortalUsers}>
            <i className="far fa-repeat mr-2"></i> Refresh Permissions
          </Button>
        </div>
      )}

      {portal && site.portal_dataset_id && (
        <div className="w-full flex justify-end">
          <Button styleType="secondary" onClick={updateDomo}>
            {!isSync && <i className="far fa-repeat mr-2"></i>} {isSync && <div className="inline-block w-5 h-5 border-[2px] rounded-full spinner-border animate-spin" role="status" />} Update Domo
            Dataset
          </Button>
        </div>
      )}

      <div className="grid space-y-8">
        <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
          <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2">Dataset</div>
          <div className="grid space-y-2 text-sm w-full lg:w-2/3">
            <FrontLabeledInput label="Name" type="text" disabled={true} value={site?.dataset_name || "Domo"} />
            <FrontLabeledInput label="ID" type="text" disabled={true} value={datasetId} />
            <div className="w-full flex items-center relative">
              <FrontLabeledInput label="URL" type="text" disabled={true} value={"https://clearsquare-co-partner.domo.com/datasources/" + datasetId + "/details/data/table"} />
              <a
                className="absolute left-16 lg:-right-7 mt-auto mb-auto"
                target="_blank"
                rel="noreferrer"
                href={"https://clearsquare-co-partner.domo.com/datasources/" + datasetId + "/details/data/table"}
              >
                <LinkIcon className="h-6 w-6 text-gray-700" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="item">
            <div className="">
              <div className="user-data">
                {!portal && <p>Dataset Name : Domo</p>}
                {portal && <p>Dataset Name : {site?.dataset_name}</p>}
                <p>Dataset ID : {datasetId}</p>
                <p>
                  Dataset URL : 
                  <a target="_blank" rel="noreferrer" href={"https://clearsquare-co-partner.domo.com/datasources/" + datasetId + "/details/data/table"}>
                    https://clearsquare-co-partner.domo.com/datasources/{datasetId}/details/data/table
                  </a>{" "}
                </p>
              </div>
            </div>
          </div> */}
      {/* {!loaded && <PreLoader />} */}

      {!portal && (
        <>
          <PortalUserList deleteId={deleteId} loaded={loaded} usersExist={datasetUsers.length}>
            {datasetUsers.length > 0 &&
              datasetUsers.map((user, i) => (
                <PortalUserEntry
                  key={user._id}
                  index={i}
                  manageUserSelect={manageUserSelect}
                  checkedState={checkedState}
                  user={{ ...user, name: user.first_name + " " + user.last_name, email: user._id }}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                />
              ))}
          </PortalUserList>
          {count > perPage && datasetUsers.length > 0 && loaded && (
            <div className="pagination">
              <div className="page-number">
                Showing {getOffsetPageCount(offset, perPage)} to {count >= (offset + 1) * perPage ? (offset + 1) * perPage : count} <span>of {count}</span>
              </div>

              {Array(pages)
                .fill()
                .map((_, i) => {
                  return (
                    <Fragment key={i}>
                      {offset > 0 && i === 0 && (
                        <div className="page-item page-prev" style={{ cursor: "pointer" }} onClick={() => setOffset(offset - 1)}>
                          <span className="page-link">
                            <i className="far fa-fw fa-chevron-double-left"></i>
                          </span>
                        </div>
                      )}
                      <div className={`page-item ${offset === i ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => setOffset(i)}>
                        <span className="page-link">{i + 1}</span>
                      </div>
                      {offset + 1 !== pages && i + 1 === pages && (
                        <div className="page-item page-next" style={{ cursor: "pointer" }} onClick={() => setOffset(offset + 1)}>
                          <span className="page-link">
                            <i className="far fa-fw fa-chevron-double-right"></i>
                          </span>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creds: state.creds,
    site: state.site,
    datasetUsers: Object.values(state.datasetUsers),
    datasetPortalUsers: Object.values(state.datasetPortalUsers),
  };
};

export default connect(mapStateToProps, { getDatasetUsers, manageDatasetUsers, domoUsersToPortalUsers, manageDatasetPortalUsers })(DatasetQuery);
