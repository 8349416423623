import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings, removeFavLogo } from "src/actions/site";
import { baseUrl, noFavi, noLogo } from "src/config/host";

import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "src/components/Shared/Button";
import { convertToBase64 } from "src/helpers/image";
import DatasetModal from "./DatasetModal";
import SitePreview from "./SitePreview";

const SiteTheme = ({ site, getPortalDatasetData, manageSiteSettings, creds, removeFavLogo }) => {
  const [image, setImage] = useState(site.site_logo);
  const [favImage, setFavImage] = useState(site.favicon);
  const [headerColor, setHeaderColor] = useState(site?.top_bar_color || "");
  const [headerTextColor, setHeaderTextColor] = useState(site?.top_bar_text_color || "#ffffff");
  const [leftNavColor, setLeftNavColor] = useState(site?.side_bar_color || "");
  const [leftNavTextColor, setLeftNavTextColor] = useState(site?.side_bar_text_color || "#ffffff");
  const [leftNavActiveColor, setLeftNavActiveColor] = useState(site?.side_bar_shade_color || "");
  const [highlightColor, setHighlightColor] = useState(site?.highlight_color || "");
  const [ssoButtonColor, setSsoButtonColor] = useState(site?.sso_button_color || "");
  const [ssoButtonTextColor, setSSOButtonTextColor] = useState(site?.sso_button_text || "#ffffff");
  const [leftNavActiveTextColor, setLeftNavActiveTextColor] = useState(site?.left_nav_active_text_color || "#ffffff");
  const [refresh, setRrefresh] = useState(false);
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];

  useEffect(() => {
    const ac = new AbortController();

    const loadPortalsDataset = async () => {
      try {
        const data = await getPortalDatasetData({}, ac.signal);
        // const saveData = await manageDatasetPortalUsers({ rows: data }, null);
        console.dir(data);
      } catch (err) {}
    };

    loadPortalsDataset();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site?._id) {
      setHeaderColor(site.top_bar_color);
      setHeaderTextColor(site.top_bar_text_color || "#ffffff");
      setLeftNavColor(site.side_bar_color);
      setLeftNavTextColor(site.side_bar_text_color || "#ffffff");
      setLeftNavActiveColor(site.side_bar_shade_color);
      setHighlightColor(site.highlight_color);
      setSsoButtonColor(site.sso_button_color);
      setSSOButtonTextColor(site.sso_button_text_color || "#ffffff");
      setLeftNavActiveTextColor(site?.left_nav_active_text_color || "#ffffff");
      setFavImage(site.favicon);
      setImage(site.site_logo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, refresh]);

  const onFavChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts, "ico", "x-icon"].includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setFavImage);
      }
    }
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const onClear = () => {
    setImage(site.site_logo || noLogo);
    setFavImage(site.favicon || noFavi);
    setHeaderColor(site.top_bar_color);
    setHeaderTextColor(site.top_bar_text_color || "#ffffff");
    setLeftNavColor(site.side_bar_color);
    setLeftNavTextColor(site.side_bar_text_color || "#ffffff");
    setLeftNavActiveColor(site.side_bar_shade_color);
    setHighlightColor(site.highlight_color);
    setSsoButtonColor(site.sso_button_color);
    setSSOButtonTextColor(site.sso_button_text_color || "#ffffff");
    setLeftNavActiveTextColor(site?.left_nav_active_text_color || "#ffffff");
  };

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        image: image,
        favicon: favImage,
        top_bar_color: headerColor,
        top_bar_text_color: headerTextColor,
        side_bar_color: leftNavColor,
        side_bar_text_color: leftNavTextColor,
        side_bar_shade_color: leftNavActiveColor,
        left_nav_active_text_color: leftNavActiveTextColor,
        highlight_color: highlightColor,
        sso_button_color: ssoButtonColor,
        sso_button_text_color: ssoButtonTextColor,
      });

      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const onFavLogoDelete = async (body) => {
    try {
      const message = await removeFavLogo(body);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md p-6 rounded-md flex flex-wrap justify-between w-full">
          <div className="w-full flex flex-wrap">
            <div className="w-full sm:w-8/12">
              <SitePreview
                site={site}
                logo={image}
                favicon={favImage}
                headerColor={headerColor}
                headerTextColor={headerTextColor}
                leftNavColor={leftNavColor}
                highlightColor={highlightColor}
                leftNavTextColor={leftNavTextColor}
                leftNavActiveColor={leftNavActiveColor}
                leftNavActiveTextColor={leftNavActiveTextColor}
                ssoButtonColor={ssoButtonColor}
                ssoButtonText={ssoButtonTextColor}
                setHeaderColor={setHeaderColor}
                setHeaderTextColor={setHeaderTextColor}
                setLeftNavTextColor={setLeftNavTextColor}
                setLeftNavColor={setLeftNavColor}
                setHighlightColor={setHighlightColor}
                setSsoButtonColor={setSsoButtonColor}
                setLeftNavActiveColor={setLeftNavActiveColor}
                setSSOButtonText={setSSOButtonTextColor}
                setLeftNavActiveTextColor={setLeftNavActiveTextColor}
              />
            </div>
            <div className="w-full sm:w-4/12 sm:pl-10">
              <div className="relative w-full mt-6">
                <div className="text-base uppercase text-black font-semibold">Site Logo</div>
                <div className="group w-60 relative h-60  border border-gray-200 p-4 rounded-md bg-headerColor">
                  {site._id && <img src={image?.includes("data:image/") ? image : baseUrl + image} alt="Logo" className="w-full h-full object-contain" onError={(e) => (e.target.src = `${noLogo}`)} />}
                  {site.site_logo && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onFavLogoDelete({ logo: true })}
                    >
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                <div className="relative w-full mt-3 overflow-hidden">
                  <label htmlFor="change-logo">
                    <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">
                      Change Logo
                    </div>
                  </label>
                  <input type="file" id="change-logo" className="hidden" onChange={onFileChange} />
                </div>
              </div>
              <div className="relative w-full mt-12">
                <div className="text-base uppercase text-black font-semibold">Favicon</div>
                <div className="group w-32 relative h-32 border border-gray-200 bg-gray-100 p-4 rounded-md">
                  {site._id && (
                    <img src={favImage?.includes("data:image/") ? favImage : baseUrl + favImage} alt="Logo" className="w-full h-full object-contain" onError={(e) => (e.target.src = `${noFavi}`)} />
                  )}
                  {site.favicon && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onFavLogoDelete({ fav: true })}
                    >
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                <div className="relative w-full mt-3 overflow-hidden">
                  <label htmlFor="change-fav">
                    <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">
                      Change Favicon
                    </div>
                  </label>
                  <input type="file" id="change-fav" className="hidden" onChange={onFavChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 w-full flex justify-end gap-x-4 pb-40">
        <Button styleType="gray" onClick={onClear}>
          Undo changes
        </Button>
        <Button onClick={onSubmit}>Update</Button>
      </div>
      <DatasetModal site={site} creds={creds} setRrefresh={setRrefresh} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    creds: state.creds,
    datasetPortalUsers: Object.values(state.datasetPortalUsers),
  };
};

export default connect(mapStateToProps, { manageSiteSettings, removeFavLogo })(SiteTheme);
