import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getSiteSettings, getSiteSettingsCache } from "src/actions/site";
import ScrollToTop from "src/components/Shared/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Layout from "src/layouts/Layout";
import routes from "src/routes/routes";
import { baseUrl } from "src/config/host";

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = (props) => {
  const { site } = props;

  useEffect(() => {
    const ac = new AbortController();

    const loadSettings = async () => {
      try {
        const data = await props.getSiteSettings({}, ac.signal);
        document.documentElement.style.setProperty("--highlightColor", data.highlight_color, "important");
        document.documentElement.style.setProperty("--headerColor", data.top_bar_color, "important");
        document.documentElement.style.setProperty("--headerTextColor", data.top_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavColor", data.side_bar_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveColor", data.side_bar_shade_color, "important");
        document.documentElement.style.setProperty("--leftNavTextColor", data.side_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveTextColor", data.left_nav_active_text_color, "important");

        if (data.favicon) {
          document.querySelector("link[rel*='icon']").href = baseUrl + data.favicon;
        }
      } catch (err) {
        throw new Error(err.message);
      }
    };

    props.getSiteSettingsCache();
    loadSettings();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(site).length) {
      document.documentElement.style.setProperty("--highlightColor", site.highlight_color, "important");
      document.documentElement.style.setProperty("--headerColor", site.top_bar_color, "important");
      document.documentElement.style.setProperty("--headerTextColor", site.top_bar_text_color, "important");
      document.documentElement.style.setProperty("--leftNavColor", site.side_bar_color, "important");
      document.documentElement.style.setProperty("--leftNavActiveColor", site.side_bar_shade_color, "important");
      document.documentElement.style.setProperty("--leftNavTextColor", site.side_bar_text_color, "important");
      document.documentElement.style.setProperty("--leftNavActiveTextColor", site.left_nav_active_text_color, "important");

      if (site.favicon) {
        document.querySelector("link[rel*='icon']").href = baseUrl + site.favicon;
      }
    }
  }, [site]);

  return (
    <BrowserRouter>
      <div className="h-screen">
        <ScrollToTop />
        <ToastContainer position="top-right" autoClose={900} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Layout>{routes}</Layout>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getSiteSettings, getSiteSettingsCache })(App);
