import NoProfile from "src/assets/img/user-default.png";
import NoFavi from "src/assets/img/favi-default.png";
import NoLogo from "src/assets/img/logo-default.png";
import NoBusiness from "src/assets/img/business-default.png";

/* Digital Ocean: Example */
// export const apiPort = "";
// export const baseUrl = "https://domo-portal-admin-api-29ypc.ondigitalocean.app";

export const apiPort = ":" + 4055;

/* Production */
// export const baseUrl = `https://admin2.clearsquare.co${apiPort}`;

/* Localhost */
export const baseUrl = `https://domolink.iosx.in${apiPort}`;

export const host = baseUrl + "/api";
export const hostUrl = host + "";
export const filePath = baseUrl + "/uploads";
export const noProfile = NoProfile;
export const noFavi = NoFavi;
export const noLogo = NoLogo;
export const noBusiness = NoBusiness;
