import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Input";
import RadioButtons from "src/components/Shared/Forms/RadioButtons";
import { classNames } from "src/helpers/classNames";
import Button from "src/components/Shared/Button";
import DatasetModal from "./DatasetModal";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const SiteCredentials = ({ site, manageSiteSettings, creds }) => {
  const navigate = useNavigate();
  const [siteName, setSiteName] = useState(site?.site_name || "");
  const [siteEmail, setSiteEmail] = useState(site?.site_email || "");
  const [siteDomain, setSiteDomain] = useState(site?.site_domain || "");
  const [adminDomain, setAdminDomain] = useState(site?.admin_domain || "");
  const [clientId, setClientId] = useState(site?.dataset_client_id || "");
  const [clientSecret, setClientSecret] = useState(site?.dataset_client_secret || "");
  const [datasetId, setDatasetId] = useState(site?.dataset_id || "");
  const [token, setToken] = useState(site?.cron_token || "");
  const [passwordState, setPasswordState] = useState(site?.password_state || false);
  const [smtpHost, setSmtpHost] = useState(site?.smtp_host || "");
  const [smtpUser, setSmtpUser] = useState(site?.smtp_user || "");
  const [smtpPassword, setSmtpPassword] = useState(site?.smtp_password || "");
  const [smtpPort, setSmtpPort] = useState(site?.smtp_port || "");
  const [smtpSecure, setSmtpSecure] = useState(site?.smtp_secure || true);
  const [portalDatasetId, setPortalDatasetId] = useState(site?.portal_dataset_id || "");
  const [domoLoginUrl, setDomoLoginUrl] = useState(site?.domo_login_url || "");
  const [ssoButtonText, setSsoButtonText] = useState(site?.sso_button_text || "");
  const [ssoAdminRedirect, setSsoAdminRedirect] = useState(site?.sso_admin_redirect || false);
  const [apiState, setApiState] = useState(site?.api_state || "portal");

  const [modalOpen, setModalOpen] = useState(false);
  const [refresh, setRrefresh] = useState(false);

  const setPageData = (site) => {
    if (site?._id) {
      setSiteName(site.site_name);
      setSiteEmail(site.site_email);
      setSiteDomain(site.site_domain);
      setAdminDomain(site.admin_domain);
      setApiState(site.api_state || "");
      setClientId(site.dataset_client_id);
      setClientSecret(site.dataset_client_secret);
      setDatasetId(site.dataset_id);
      setToken(site.cron_token);
      setPasswordState(site.password_state || false);
      setSmtpUser(site.smtp_user || "");
      setSmtpHost(site.smtp_host || "");
      setSmtpPassword(site.smtp_password || "");
      setSmtpPort(site.smtp_port || "");
      setSmtpSecure(site.smtp_secure || true);
      setPortalDatasetId(site.portal_dataset_id || "");
      setDomoLoginUrl(site.domo_login_url || "");
      setSsoButtonText(site.sso_button_text || "");
      setSsoAdminRedirect(site.sso_admin_redirect || true);
    }
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, refresh]);

  const onClear = () => {
    setPageData();
  };

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        site_name: siteName,
        site_email: siteEmail,
        site_domain: siteDomain,
        admin_domain: adminDomain,
        api_state: apiState,
        dataset_client_id: clientId,
        dataset_client_secret: clientSecret,
        dataset_id: datasetId,
        cron_token: token,
        password_state: passwordState,
        smtp_host: smtpHost,
        smtp_user: smtpUser,
        smtp_password: smtpPassword,
        smtp_port: smtpPort,
        domo_login_url: domoLoginUrl,
        sso_button_text: ssoButtonText,
        portal_dataset_id: portalDatasetId,
        smtp_secure: smtpSecure,
        sso_admin_redirect: ssoAdminRedirect,
      });

      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const randomHashString = (length) => {
    let result = "";
    const characters = "**************************************************************";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  //   const dsid = site.portal_dataset_id;
  //   const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");
  //   //const [dataUrl] = useState(`https://api.domo.com/v1/json/${dsid}/data`);
  //   const loadAccessToken = async () => {
  //     const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
  //     const data = await getDatasetAccess(
  //       null,
  //       null,
  //       {
  //         Authorization: `Basic ${base64Cred}`,
  //       },
  //       authUrl
  //     );

  //     return data.access_token;
  //   };

  //   const loadDatasetQuery = async () => {
  //     const accessToken = await loadAccessToken();
  //     if (datasetId !== undefined) {
  //       const data = await getDatasetQuery(
  //         { sql: "SELECT * FROM table" },
  //         null,
  //         {
  //           Authorization: `bearer ${accessToken}`,
  //         },
  //         `https://api.domo.com/v1/datasets/query/execute/${dsid}`
  //       );

  //       return data.rows;
  //     } else {
  //       return [];
  //     }
  //   };

  return (
    <div className="grid space-y-8">
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2">Site Data</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* Site name */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="site-name" label="Name" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
          </div>

          {/* Contact email */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="contact-email" label="Contact email" value={siteEmail} onChange={(e) => setSiteEmail(e.target.value)} />
          </div>

          {/* Site domain */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="site-domain" label="User domain" value={siteDomain} onChange={(e) => setSiteDomain(e.target.value)} />
          </div>

          {/* Admin domain */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="admin-domain" label="Admin domain" value={adminDomain} onChange={(e) => setAdminDomain(e.target.value)} />
          </div>
        </div>

        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2 mt-5">SSO Details</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* SSO Button Text */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="sso-button-text" label="Button Text" value={ssoButtonText} onChange={(e) => setSsoButtonText(e.target.value)} />
          </div>
          <div className="sm:w-1/2 w-full px-2">
            <div className="relative flex flex-col pl-3 pt-3.5">
              <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">URL Redirect</div>
              <div className="pt-1 md:pt-0 items-center flex">
                <input
                  checked={ssoAdminRedirect}
                  onChange={(e) => setSsoAdminRedirect(e.target.checked)}
                  aria-describedby="notifications-description"
                  name="notifications"
                  type="checkbox"
                  className="h-4 w-4 text-highlightColor border-gray-300 rounded"
                />
                <p className="ml-3 text-sm text-gray-500">Redirect admin portal to SSO login</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2 mt-5">SMTP</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* SMTP USER */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="smtp-user" label="User" value={smtpUser} onChange={(e) => setSmtpUser(e.target.value)} />
          </div>

          {/* SMTP PASSWORD */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="smtp-password"
              label="Password"
              value={randomHashString(smtpPassword.length)}
              onChange={(e) => setSmtpPassword(e.target.value)}
              onFocus={(e) => {
                if (e.target.value.includes("*")) {
                  e.target.value = "";
                }
              }}
            />
          </div>

          {/* SMTP HOST */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="smtp-host" label="Host" value={smtpHost} onChange={(e) => setSmtpHost(e.target.value)} />
          </div>
          {/* SMTP port */}
          <div className="relative sm:w-1/2 w-full px-2">
            {/* SMTP HOST */}
            <div className="absolute z-30 flex items-center gap-x-2 ml-2 top-[2px] right-4 text-gray-700 opacity-70 text-xs cursor-pointer">
              <input className="rounded-sm text-gray-400 focus:ring-highlightColor cursor-pointer" type="checkbox" checked={smtpSecure} onChange={(e) => setSmtpSecure(e.target.checked)} />
              <label className="cursor-pointer">STMP Secure</label>
            </div>

            <div className="w-full">
              <Input label="SMTP Port" value={smtpPort} onChange={(e) => setSmtpPort(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-col lg:flex-row justify-between w-full gap-y-6 lg:gap-y-0 lg:gap-x-12">
        {/* Select portal */}
        <div className="w-[270px] md:min-w-[362px]">
          <RadioButtons
            heading="User Permissions"
            subheading="How do you prefer to set your user permissions?"
            items={[
              { id: "portal", name: "Set individual permissions", checked: "portal" === apiState, onChange: () => setApiState("portal") },
              { id: "domo", name: "Use Domo dataset", checked: "domo" === apiState, onChange: () => setApiState("domo") },
            ]}
          />
        </div>

        <div className="relative rounded-md lg:w-2/3 w-full border border-gray-100">
          <div className={classNames("h-full w-full absolute flex items-center justify-center bg-gray-100 opacity-80 z-10", apiState !== "domo" ? "absolute" : "hidden")}>
            <div className="w-full flex justify-center rotate-[-10deg] text-4xl md:text-4xl lg:text-5xl xl:text-7xl font-extrabold text-gray-300 text-center select-none">For Domo Dataset Use</div>
          </div>
          <div className="px-4 py-6">
            <div className="grid space-y-2 text-sm">
              <FrontLabeledInput
                label="Client ID"
                type="password"
                disabled={apiState !== "domo"}
                value={randomHashString(clientId?.length)}
                onChange={(e) => setClientId(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <FrontLabeledInput
                label="Client Secret"
                type="password"
                disabled={apiState !== "domo"}
                value={randomHashString(clientSecret?.length)}
                onChange={(e) => setClientSecret(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <FrontLabeledInput label="Dataset ID" type="text" disabled={apiState !== "domo"} value={datasetId} onChange={(e) => setDatasetId(e.target.value)} />
            </div>
            <div className="flex justify-between flex-wrap space-y-7 w-full mt-10">
              {/* Select Password Update Status */}
              <div className="min-w-[260px]">
                <RadioButtons
                  disabled={apiState !== "domo"}
                  heading="Overwrite user passwords"
                  subheading="Does your dataset contain your user's passwords?"
                  items={[
                    { id: "yes", name: "Yes", checked: passwordState === true, onChange: () => setPasswordState(true) },
                    { id: "no", name: "No", checked: passwordState === false, onChange: () => setPasswordState(false) },
                  ]}
                />
              </div>
              {/* Go to dataset users */}
              <Button
                styleType="gray"
                disabled={apiState !== "domo"}
                className="w-full"
                onClick={() => {
                  navigate(`/datasetusers`);
                }}
              >
                View dataset users
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full gap-y-6">
        {(portalDatasetId || site?.portal_dataset_id) && (
          <div className="flex justify-between items-end flex-wrap space-y-7 w-full">
            {/* Select Password Update Status */}
            <div className="w-full sm:w-1/2 px-2">
              <Input name="portal-dataset-id" label="Domo Dataset ID" value={portalDatasetId} onChange={(e) => setPortalDatasetId(e.target.value)} />
            </div>
            {/* Go to dataset users */}
            <div className="w-full sm:w-1/2 px-2">
              <Input name="domo-login-url" label="Domo Login URL" value={domoLoginUrl} onChange={(e) => setDomoLoginUrl(e.target.value)} />
            </div>
            <a
              href={domoLoginUrl + "/datasources/" + portalDatasetId + "/details/data/table"}
              className="ml-2 text-regular font-medium text-gray-500 hover:text-highlightColor hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center gap-x-2">
                <p>Link to domo dataset</p>
                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
              </div>
            </a>
          </div>
        )}
        {!portalDatasetId && !site?.portal_dataset_id && (
          <div className="col-lg-3 mt-4">
            <Button styleType="gray" onClick={() => setModalOpen(!modalOpen)}>
              Create Domo Dataset
            </Button>
          </div>
        )}
      </div>

      <div className="w-full flex justify-end gap-x-4 pb-40">
        <Button styleType="gray" onClick={onClear}>
          Undo changes
        </Button>
        <Button onClick={onSubmit}>Update</Button>
      </div>
      <DatasetModal site={site} creds={creds} setRrefresh={setRrefresh} isOpen={modalOpen} setIsOpen={setModalOpen} submitText="Save" cancelText="Cancel" title="Dataset" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    creds: state.creds,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteCredentials);
