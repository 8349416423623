import SiteColorItem from "./SiteColorItem";
import { noLogo, noFavi, baseUrl } from "src/config/host";
import { XMarkIcon } from "@heroicons/react/24/solid";

const SitePreview = ({
  site,
  logo,
  favicon,
  headerColor,
  headerTextColor,
  ssoButtonText,
  leftNavColor,
  highlightColor,
  leftNavTextColor,
  ssoButtonColor,
  leftNavActiveColor,
  leftNavActiveTextColor,
  ...props
}) => {
  const { setHeaderColor, setHeaderTextColor, setLeftNavTextColor, setLeftNavColor, setHighlightColor, setLeftNavActiveTextColor, setSsoButtonColor, setSSOButtonText, setLeftNavActiveColor } = props;

  return (
    <>
      <div className="relative">
        <div className="relative w-full hidden sm:flex flex-wrap border border-gray-200 rounded-lg mt-12">
          <div className="absolute -top-9 left-4 h-9 border-[1px] border-gray-200 bg-gray-100 rounded-t-lg flex px-3 justify-between items-center">
            <div className="flex items-center gap-x-3">
              <img className="py-[2px] h-6 w-auto" src={favicon?.includes("data:image/") ? favicon : baseUrl + favicon} onError={(e) => (e.target.src = `${noFavi}`)} alt="logo" />
              <p className="text-gray-600 mr-4 text-center">{site.site_name}</p>
            </div>
            <XMarkIcon className="h-4 w-4 text-gray-600" />
          </div>
          <div style={{ backgroundColor: headerColor }} className="relative w-full flex items-center justify-between h-16 px-6 rounded-t-lg">
            <img className="py-[2px] h-6 w-auto" src={logo?.includes("data:image/") ? logo : baseUrl + logo} onError={(e) => (e.target.src = `${noLogo}`)} alt="logo" />
            <div style={{ backgroundColor: headerTextColor }} className="rounded-full w-6 h-6"></div>
            <div className="absolute z-40 -top-12 left-56 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem onChange={setHeaderColor} color={headerColor} name="Header" />
            </div>
            <div className="absolute z-10 -top-12 right-4 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:right-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem onChange={setHeaderTextColor} color={headerTextColor} name="Header text" />
            </div>
          </div>
          <div style={{ backgroundColor: leftNavColor }} className="relative w-3/12 space-y-4 p-4 rounded-bl-lg">
            <div style={{ color: leftNavActiveTextColor, backgroundColor: leftNavActiveColor }} className="h-5 py-4 px-3 font-medium flex items-center rounded text-leftNavActiveTextColor">
              Dashboards
            </div>
            {["Users", "Admins", "Groups", "SSO", "Activity", "Settings"].map((menu) => {
              return (
                <div key={menu} style={{ color: leftNavTextColor }} className="h-5 py-4 px-3 rounded font-medium flex items-center">
                  {menu}
                </div>
              );
            })}
            <div className="absolute z-30 top-8 left-12 bg-white flex flex-col w-[160px] border border-gray-100 gap-y-4 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem onChange={setLeftNavActiveColor} color={leftNavActiveColor} name="Navigation Active" />
              <SiteColorItem onChange={setLeftNavActiveTextColor} color={leftNavActiveTextColor} name="Navigation Active Text" />
            </div>

            <div className="absolute z-20 top-[150px] left-6 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem onChange={setLeftNavTextColor} color={leftNavTextColor} name="Navigation text" />
            </div>
            <div className="absolute z-10 top-[310px] left-12 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem onChange={setLeftNavColor} color={leftNavColor} name="Navigation background" />
            </div>
          </div>
          <div className="relative w-9/12 bg-gray-50 space-y-4 p-4 rounded-br-lg">
            <div className="absolute z-20 top-16 left-32 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem onChange={setHighlightColor} color={highlightColor} name="Highlight" />
            </div>

            <div className="bg-highlightColor h-4 w-48 rounded" style={{ backgroundColor: highlightColor }}></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 w-48 rounded"></div>
            <div className="h-12"></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 w-48 rounded"></div>
            <div className="h-12"></div>
            <div className="flex space-x-4">
              <div className="mt-14 bg-gray-400 h-10 px-3 rounded-md flex items-center justify-center" style={{ backgroundColor: ssoButtonColor }}>
                <p className="h-6 flex items-center justify-center rounded-md font-medium ml-2" style={{ color: ssoButtonText }}>
                  {site.sso_button_text}
                </p>
              </div>
            </div>
            <div className="h-12"></div>
            <div className="absolute z-10 bottom-32 left-32 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem onChange={setSsoButtonColor} color={ssoButtonColor} name="SSO Button Background" />
            </div>

            <div className="absolute z-20 bottom-3 left-[70px] bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem onChange={setSSOButtonText} color={ssoButtonText} name="SSO Button Text" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col sm:hidden">
        <div className="mb-4">
          <SiteColorItem onChange={setHeaderColor} color={headerColor} name="Header" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setHeaderTextColor} color={headerTextColor} name="Header text" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setLeftNavColor} color={leftNavColor} name="Navigation background" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setLeftNavTextColor} color={leftNavTextColor} name="Navigation text" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setLeftNavActiveColor} color={leftNavActiveColor} name="Navigation Active" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setLeftNavActiveTextColor} color={leftNavActiveTextColor} name="Navigation Active Text" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setHighlightColor} color={highlightColor} name="Highlight" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setSsoButtonColor} color={ssoButtonColor} name="SSO Button Background" />
        </div>
        <div className="mb-4">
          <SiteColorItem onChange={setSSOButtonText} color={ssoButtonText} name="SSO Button Text" />
        </div>
      </div>
    </>
  );
};

export default SitePreview;
